import type Role from "../../../common/src/models/postgres/role";
import type User from "../../../common/src/models/postgres/user";
import type { requestContext } from "@fastify/request-context";
import Permission from "../models/postgres/permission";
import RolePermission from "../models/postgres/rolePermission";
import UserConfig from "../models/postgres/userConfig";

export enum ErrorMessage {
  Invalid_Input = "Invalid input",
  OTP_Invalid = "Invalid OTP Code",
  User_Existed = "User already existed",
  User_Not_Existed = "User not existed",
  Un_Authorized = "Authorization header seems missing",
  Access_Denied = "You do not have permission to access this resource",
  Account_Blocked = "This account has been locked",
  Invalid_Input_Password = "Invalid input password",
  Email_Invalid = "Email invalid",
  Invalid_Input_Temporary_Password = "Invalid input temporary password",
  Invalid_Cap_Auth_Key = "Invalid CAP_AUTH_KEY",
  Invalid_Encrypt_Key = "Invalid ENCRYPT_KEY",
  Invalid_Zip_File = "Invalid structure of zip file",
  Empty_Parameters = "Empty parameters",
}

export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum RequestMethod {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  OPTIONS = "options",
  HEAD = "head",
}

export enum ErrorCode {
  Unknown_Error = 0,
  Invalid_Input = 1,
}

export enum GroupRole {
  Enterprise = 1,
  Reseller = 2,
}

export enum SimStatus {
  ActovatonReady = "ACTIVATION_READY_NAME",
  Replaced = "REPLACED_NAME",
  Activated = "ACTIVATED_NAME",
  Deactivated = "DEACTIVATED_NAME",
}

export enum SimTypeChange {
  Status = 3,
}

export enum STATUS_SIM {
  PRE_ACTIVATED = "PRE_ACTIVATED",
  SUSPENDED = "SUSPENDED",
  AVAILABLE = "AVAILABLE",
  TERMINATED = "TERMINATED",
  TARIFF_HOLIDAY = "TARIFF_HOLIDAY",
  ACTIVE = "ACTIVE",
  ACTIVATED_NAME = "ACTIVATED_NAME",
  DEACTIVATED_NAME = "DEACTIVATED_NAME",
  INVENTORY_NAME = "INVENTORY_NAME",
  ACTIVATION_READY_NAME = "ACTIVATION_READY_NAME",
  RETIRED_NAME = "RETIRED_NAME",
  NTT_COM_ACTIVE = "NTT_COM_ACTIVE",
  NTT_COM_DEACTIVE = "NTT_COM_DEACTIVE",
}

export enum SortType {
  ASC = "ASC",
  DESC = "DESC",
}

interface RolePermissionAndPermission extends RolePermission {
  permission: Permission;
}

interface RoleAndRolePermissions extends Role {
  permissions: RolePermissionAndPermission[]
}

export interface JoinUserAndRole extends User {
  role?: RoleAndRolePermissions | null
}

export interface JoinUserAndRoleAndConfig extends JoinUserAndRole {
  userConfig: UserConfig | null;
}

export enum Lang {
  JA = "ja",
  EN = "en",
};

declare module "@fastify/request-context" {
  interface RequestContextData {
    [RequestContextKey.USER]: JoinUserAndRoleAndConfig | null;
  }
}

export enum RequestContextKey {
  USER = "user",
}

export enum PermissionKey {
  // GROUP
  GROUP__CREATE = "GROUP__CREATE",
  GROUP__UPDATE = "GROUP__UPDATE",
  GROUP__DELETE = "GROUP__DELETE",
  GROUP__DELETE_MULTIPLE = "GROUP__DELETE_MULTIPLE",
  // SIM
  SIM__CREATE = "SIM__CREATE",
  SIM__UPDATE = "SIM__UPDATE",
  SIM__DELETE = "SIM__DELETE",
  // USER
  USER__CREATE = "USER__CREATE",
  USER__UPDATE = "USER__UPDATE",
  USER__DELETE = "USER__DELETE",
  // SETTING
  SETTING__LANGUAGE_DATETIME = "SETTING__LANGUAGE_DATETIME",
  SETTING__THEME = "SETTING__THEME",
  SETTING__SYSTEM_MAIL = "SETTING__SYSTEM_MAIL",
  SETTING__NOTIFY_PASSWORD = "SETTING__NOTIFY_PASSWORD",
  SETTING__PASSWORD_SECURITY = "SETTING__PASSWORD_SECURITY",
  // LOGS
  LOGS__SYSTEM_LOG = "LOGS__SYSTEM_LOG",
  LOGS__RAW_LOG = "LOGS__RAW_LOG",
  // STATISTIC
  STATISTIC__MANAGEMENT = "STATISTIC__MANAGEMENT",
  // OPENAPI
  OPENAPI_GENERATE = "OPENAPI_GENERATE",
  // ACCESS RESTRICTIONS
  SETTING__ACCESS_RESTRICTIONS = "SETTING__ACCESS_RESTRICTIONS",
  // OTA
  OTA__CREATE = "OTA__CREATE",
  OTA__UPDATE = "OTA__UPDATE",
  OTA__DELETE = "OTA__DELETE",
  // IOTSAFE CERTIFICATES
  IOTSAFE_CERTIFICATES__CREATE = "IOTSAFE_CERTIFICATES__CREATE",
  IOTSAFE_CERTIFICATES__UPDATE = "IOTSAFE_CERTIFICATES__UPDATE",
  IOTSAFE_CERTIFICATES__DELETE = "IOTSAFE_CERTIFICATES__DELETE",
}

export enum WebSocketEvent {
  Ping = "PING",
  SimUpdated = "SIM_UPDATED",
  SimDataRecieved = "SIM_DATA_RECIEVED",
  OTALog = "OTA_LOG",
  CameraEvent = "CAMERA_EVENT",
}

export enum RoleName {
  ROOT = "ROOT",
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

export enum RoleId {
  ROOT = 1,
  ADMIN = 2,
  MEMBER = 3,
}

export enum WeekDay {
  SUNDAY = 1,
  MONDAY = 2,
  TUESDAY = 3,
  WEDNESDAY = 4,
  THURSDAY = 5,
  FRIDAY = 6,
  SATURDAY = 7,
}

export enum AmPm {
  AM = 1,
  PM = 2,
}

export enum SettingType {
  SYSTEM_MAIL = "system_mail",
  NOTIFY_PASSWORD_INVITATION = "notify_password_invitation",
  NOTIFY_PASSWORD_AUTHENTICATION = "notify_password_authentication",
  SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH_KEY = "system_password_minimum",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_UPPER_CASE_KEY = "system_password_upper_case",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_LOWER_CASE_KEY = "system_password_lower_case",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_NUMBER_KEY = "system_password_number",
  SYSTEM_CONFIG_PASSWORD_CHARACTER_SYMBOL_KEY = "system_password_symbol",
  SYSTEM_CONFIG_PASSWORD_MINIMUM_LENGTH = 8,
  SYSTEM_CONFIG_GOOGLE_MAPS_API_KEY_KEY = "system_config_google_maps_api_key",
  USER_LANGUAGE = "user_language",
  SYSTEM_CONFIG_SIM_ONLINE_TERM = "system_config_sim_online_term",
}

export enum CommonValueCheckbox {
  ACTIVE = 2,
  INACTIVE = 1,
}

export enum UserConfigType {
  SYSTEM = 1,
  USER = 2,
}

export const VERSION_DEFAULT = "1.0.0";

export enum MsgAwsCognito {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export enum MemberStatus {
  ACTIVE = 1,
  IN_CONFIRM = 2,
  INACTIVE = 0,
}

export enum ErrorLogAction {
  MEMBER_DELETE = "DELETE MEMBER",
  MEMBER_UPDATE = "UPDATE MEMBER",
  MEMBER_ADD = "ADD MEMBER",
  //XOMG

  SIM_ADD = "ADD SIM",
  SIM_UPDATE = "UPDATE SIM",
  SIM_DELETE = "DELETE SIM",
  SIM_WEBHOOK = "WEBHOOK SIM",
  SIM_SQS = "SQS SIM",
  SIM_CRONJOB = "CRONJOB SIM",
  SIM_IMPORT = "SIM_IMPORT",
  SIM_EXPORT = "SIM_EXPORT",

  RECIEVE_FROM_SIM = "RECIEVE FROM SIM",

  SETTING_CHANGE = "CHANGE SETTING",

  AUTH_CREATE_ACCOUNT = "CREATE ACCOUNT AUTH",
  AUTH_FORGOT_PASSWORD = "FORGOT PASSWORD AUTH",

  PASSWORD_SETTING_CHANGEMFA = "CHANGE MFA SETTING",

  OPEN_API_CREATE = "OPEN_API_CREATE",
  OPEN_API_DELETE = "OPEN_API_DELETE",
}

export enum EVENT_BRIDGE_TYPE {
  UPDATE_STATUS = 1,
}

export enum ConnectionSim {
  OTA = 2,
  ONLINE = 1,
  OFFLINE = 0,
}

export enum ImeiStatus {
  NOT_CHANGED = '0',
  CHANGED = '1',
}

export enum AppletType {
  Poller = 0,
  Other = 1,
}

export enum OTAStatus {
  Failed = "Failed",
  Started = "Started",
  Succeeded = "Succeeded",
  Interrupted = "Interrupted",
  Ready = "Ready",
  Blocked = "Blocked",
  NotFound = "Not found",
}

export enum EventCode {
  POLL = 1,
  COMMAND_DATA = 2,
  LOAD_COMMAND = 3,
  SCP_INIT = 4,
  RES_FIRST_CHUNK = 5,
  RES_COMMAND_APDU = 6,
  CLOSE_SESSION = 7,
}

/**
 * 100番台：例外エラー
 * 200番台：サーバーエラー(無効なデータなど)
 * 300番台：認証エラー
 * 400番台：通信エラー
 * 500番台：SIMからのレスポンスエラー
 */
export enum ReasonCode {
  EXCEPTION = 100,
  MISSING_OTA_SETTING = 101,
  NOT_EXIST_APUD = 200,
  INVALID_CHUNK = 201,
  EMPTY_CHUNK = 202,
  NOT_RESITERED_PARAMETER = 203,
  FAILED_AUTHORIZE = 300,
  NOT_EXIST_SESSION = 400,
  ABORTED_SESSION = 401,
  MAX_RETRY_COUNT = 402,
  R_APDU_ERROR = 500,
  POLLER_UPDATE_IS_BLOCKED = 501,
}

export enum ClientType {
  WebUI = "WEB_UI",
  OpenAPI = "OPEN_API",
}

export enum ActionStatus {
  Success = "Request return success",
  Error = "Request return error",
}

export enum PollingType {
  Default = 0, // NOTE: 有効・初回起動時のみの2つを使用できる
  Resiliency = 1, // NOTE: 有効・初回起動時のみ・無効の3つを使用できる
}

export enum ActionTypeId {
  INSTALL_PACKAGE = 1,
  REMOVE_PACKAGE = 2,
  STORE_DATA = 3,
  RFM = 4,
}

export const isoCountries = [
  { title: "Afghanistan", value: "AF" },
  { title: "Åland Islands", value: "AX" },
  { title: "Albania", value: "AL" },
  { title: "Algeria", value: "DZ" },
  { title: "American Samoa", value: "AS" },
  { title: "Andorra", value: "AD" },
  { title: "Angola", value: "AO" },
  { title: "Anguilla", value: "AI" },
  { title: "Antarctica", value: "AQ" },
  { title: "Antigua and Barbuda", value: "AG" },
  { title: "Argentina", value: "AR" },
  { title: "Armenia", value: "AM" },
  { title: "Aruba", value: "AW" },
  { title: "Australia", value: "AU" },
  { title: "Austria", value: "AT" },
  { title: "Azerbaijan", value: "AZ" },
  { title: "Bahamas", value: "BS" },
  { title: "Bahrain", value: "BH" },
  { title: "Bangladesh", value: "BD" },
  { title: "Barbados", value: "BB" },
  { title: "Belarus", value: "BY" },
  { title: "Belgium", value: "BE" },
  { title: "Belize", value: "BZ" },
  { title: "Benin", value: "BJ" },
  { title: "Bermuda", value: "BM" },
  { title: "Bhutan", value: "BT" },
  { title: "Bolivia (Plurinational State of)", value: "BO" },
  { title: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { title: "Bosnia and Herzegovina", value: "BA" },
  { title: "Botswana", value: "BW" },
  { title: "Bouvet Island", value: "BV" },
  { title: "Brazil", value: "BR" },
  { title: "British Indian Ocean Territory", value: "IO" },
  { title: "Brunei Darussalam", value: "BN" },
  { title: "Bulgaria", value: "BG" },
  { title: "Burkina Faso", value: "BF" },
  { title: "Burundi", value: "BI" },
  { title: "Cabo Verde", value: "CV" },
  { title: "Cambodia", value: "KH" },
  { title: "Cameroon", value: "CM" },
  { title: "Canada", value: "CA" },
  { title: "Cayman Islands", value: "KY" },
  { title: "Central African Republic", value: "CF" },
  { title: "Chad", value: "TD" },
  { title: "Chile", value: "CL" },
  { title: "China", value: "CN" },
  { title: "Christmas Island", value: "CX" },
  { title: "Cocos (Keeling) Islands", value: "CC" },
  { title: "Colombia", value: "CO" },
  { title: "Comoros", value: "KM" },
  { title: "Congo", value: "CG" },
  { title: "Congo, Democratic Republic of the", value: "CD" },
  { title: "Cook Islands", value: "CK" },
  { title: "Costa Rica", value: "CR" },
  { title: "Côte d'Ivoire", value: "CI" },
  { title: "Croatia", value: "HR" },
  { title: "Cuba", value: "CU" },
  { title: "Curaçao", value: "CW" },
  { title: "Cyprus", value: "CY" },
  { title: "Czechia", value: "CZ" },
  { title: "Denmark", value: "DK" },
  { title: "Djibouti", value: "DJ" },
  { title: "Dominica", value: "DM" },
  { title: "Dominican Republic", value: "DO" },
  { title: "Ecuador", value: "EC" },
  { title: "Egypt", value: "EG" },
  { title: "El Salvador", value: "SV" },
  { title: "Equatorial Guinea", value: "GQ" },
  { title: "Eritrea", value: "ER" },
  { title: "Estonia", value: "EE" },
  { title: "Eswatini", value: "SZ" },
  { title: "Ethiopia", value: "ET" },
  { title: "Falkland Islands (Malvinas)", value: "FK" },
  { title: "Faroe Islands", value: "FO" },
  { title: "Fiji", value: "FJ" },
  { title: "Finland", value: "FI" },
  { title: "France", value: "FR" },
  { title: "French Guiana", value: "GF" },
  { title: "French Polynesia", value: "PF" },
  { title: "French Southern Territories", value: "TF" },
  { title: "Gabon", value: "GA" },
  { title: "Gambia", value: "GM" },
  { title: "Georgia", value: "GE" },
  { title: "Germany", value: "DE" },
  { title: "Ghana", value: "GH" },
  { title: "Gibraltar", value: "GI" },
  { title: "Greece", value: "GR" },
  { title: "Greenland", value: "GL" },
  { title: "Grenada", value: "GD" },
  { title: "Guadeloupe", value: "GP" },
  { title: "Guam", value: "GU" },
  { title: "Guatemala", value: "GT" },
  { title: "Guernsey", value: "GG" },
  { title: "Guinea", value: "GN" },
  { title: "Guinea-Bissau", value: "GW" },
  { title: "Guyana", value: "GY" },
  { title: "Haiti", value: "HT" },
  { title: "Heard Island and McDonald Islands", value: "HM" },
  { title: "Holy See", value: "VA" },
  { title: "Honduras", value: "HN" },
  { title: "Hong Kong", value: "HK" },
  { title: "Hungary", value: "HU" },
  { title: "Iceland", value: "IS" },
  { title: "India", value: "IN" },
  { title: "Indonesia", value: "ID" },
  { title: "Iran (Islamic Republic of)", value: "IR" },
  { title: "Iraq", value: "IQ" },
  { title: "Ireland", value: "IE" },
  { title: "Isle of Man", value: "IM" },
  { title: "Israel", value: "IL" },
  { title: "Italy", value: "IT" },
  { title: "Jamaica", value: "JM" },
  { title: "Japan", value: "JP" },
  { title: "Jersey", value: "JE" },
  { title: "Jordan", value: "JO" },
  { title: "Kazakhstan", value: "KZ" },
  { title: "Kenya", value: "KE" },
  { title: "Kiribati", value: "KI" },
  { title: "Korea (Democratic People's Republic of)", value: "KP" },
  { title: "Korea, Republic of", value: "KR" },
  { title: "Kuwait", value: "KW" },
  { title: "Kyrgyzstan", value: "KG" },
  { title: "Lao People's Democratic Republic", value: "LA" },
  { title: "Latvia", value: "LV" },
  { title: "Lebanon", value: "LB" },
  { title: "Lesotho", value: "LS" },
  { title: "Liberia", value: "LR" },
  { title: "Libya", value: "LY" },
  { title: "Liechtenstein", value: "LI" },
  { title: "Lithuania", value: "LT" },
  { title: "Luxembourg", value: "LU" },
  { title: "Macao", value: "MO" },
  { title: "Madagascar", value: "MG" },
  { title: "Malawi", value: "MW" },
  { title: "Malaysia", value: "MY" },
  { title: "Maldives", value: "MV" },
  { title: "Mali", value: "ML" },
  { title: "Malta", value: "MT" },
  { title: "Marshall Islands", value: "MH" },
  { title: "Martinique", value: "MQ" },
  { title: "Mauritania", value: "MR" },
  { title: "Mauritius", value: "MU" },
  { title: "Mayotte", value: "YT" },
  { title: "Mexico", value: "MX" },
  { title: "Micronesia (Federated States of)", value: "FM" },
  { title: "Moldova, Republic of", value: "MD" },
  { title: "Monaco", value: "MC" },
  { title: "Mongolia", value: "MN" },
  { title: "Montenegro", value: "ME" },
  { title: "Montserrat", value: "MS" },
  { title: "Morocco", value: "MA" },
  { title: "Mozambique", value: "MZ" },
  { title: "Myanmar", value: "MM" },
  { title: "Namibia", value: "NA" },
  { title: "Nauru", value: "NR" },
  { title: "Nepal", value: "NP" },
  { title: "Netherlands", value: "NL" },
  { title: "New Caledonia", value: "NC" },
  { title: "New Zealand", value: "NZ" },
  { title: "Nicaragua", value: "NI" },
  { title: "Niger", value: "NE" },
  { title: "Nigeria", value: "NG" },
  { title: "Niue", value: "NU" },
  { title: "Norfolk Island", value: "NF" },
  { title: "North Macedonia", value: "MK" },
  { title: "Northern Mariana Islands", value: "MP" },
  { title: "Norway", value: "NO" },
  { title: "Oman", value: "OM" },
  { title: "Pakistan", value: "PK" },
  { title: "Palau", value: "PW" },
  { title: "Palestine, State of", value: "PS" },
  { title: "Panama", value: "PA" },
  { title: "Papua New Guinea", value: "PG" },
  { title: "Paraguay", value: "PY" },
  { title: "Peru", value: "PE" },
  { title: "Philippines", value: "PH" },
  { title: "Pitcairn", value: "PN" },
  { title: "Poland", value: "PL" },
  { title: "Portugal", value: "PT" },
  { title: "Puerto Rico", value: "PR" },
  { title: "Qatar", value: "QA" },
  { title: "Réunion", value: "RE" },
  { title: "Romania", value: "RO" },
  { title: "Russian Federation", value: "RU" },
  { title: "Rwanda", value: "RW" },
  { title: "Saint Barthélemy", value: "BL" },
  { title: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
  { title: "Saint Kitts and Nevis", value: "KN" },
  { title: "Saint Lucia", value: "LC" },
  { title: "Saint Martin (French part)", value: "MF" },
  { title: "Saint Pierre and Miquelon", value: "PM" },
  { title: "Saint Vincent and the Grenadines", value: "VC" },
  { title: "Samoa", value: "WS" },
  { title: "San Marino", value: "SM" },
  { title: "Sao Tome and Principe", value: "ST" },
  { title: "Saudi Arabia", value: "SA" },
  { title: "Senegal", value: "SN" },
  { title: "Serbia", value: "RS" },
  { title: "Seychelles", value: "SC" },
  { title: "Sierra Leone", value: "SL" },
  { title: "Singapore", value: "SG" },
  { title: "Sint Maarten (Dutch part)", value: "SX" },
  { title: "Slovakia", value: "SK" },
  { title: "Slovenia", value: "SI" },
  { title: "Solomon Islands", value: "SB" },
  { title: "Somalia", value: "SO" },
  { title: "South Africa", value: "ZA" },
  { title: "South Georgia and the South Sandwich Islands", value: "GS" },
  { title: "South Sudan", value: "SS" },
  { title: "Spain", value: "ES" },
  { title: "Sri Lanka", value: "LK" },
  { title: "Sudan", value: "SD" },
  { title: "Suriname", value: "SR" },
  { title: "Svalbard and Jan Mayen", value: "SJ" },
  { title: "Sweden", value: "SE" },
  { title: "Switzerland", value: "CH" },
  { title: "Syrian Arab Republic", value: "SY" },
  { title: "Taiwan, Province of China", value: "TW" },
  { title: "Tajikistan", value: "TJ" },
  { title: "Tanzania, United Republic of", value: "TZ" },
  { title: "Thailand", value: "TH" },
  { title: "Timor-Leste", value: "TL" },
  { title: "Togo", value: "TG" },
  { title: "Tokelau", value: "TK" },
  { title: "Tonga", value: "TO" },
  { title: "Trinidad and Tobago", value: "TT" },
  { title: "Tunisia", value: "TN" },
  { title: "Turkey", value: "TR" },
  { title: "Turkmenistan", value: "TM" },
  { title: "Turks and Caicos Islands", value: "TC" },
  { title: "Tuvalu", value: "TV" },
  { title: "Uganda", value: "UG" },
  { title: "Ukraine", value: "UA" },
  { title: "United Arab Emirates", value: "AE" },
  { title: "United Kingdom of Great Britain and Northern Ireland", value: "GB" },
  { title: "United States of America", value: "US" },
  { title: "United States Minor Outlying Islands", value: "UM" },
  { title: "Uruguay", value: "UY" },
  { title: "Uzbekistan", value: "UZ" },
  { title: "Vanuatu", value: "VU" },
  { title: "Venezuela (Bolivarian Republic of)", value: "VE" },
  { title: "Viet Nam", value: "VN" },
  { title: "Virgin Islands (British)", value: "VG" },
  { title: "Virgin Islands (U.S.)", value: "VI" },
  { title: "Wallis and Futuna", value: "WF" },
  { title: "Western Sahara", value: "EH" },
  { title: "Yemen", value: "YE" },
  { title: "Zambia", value: "ZM" },
  { title: "Zimbabwe", value: "ZW" },
] as const;

